import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as React from "react";
//theme
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";
//pages
import MainPage from "./pages/main.tsx";
import Error from "./pages/error.tsx";
import AnimalQ from "./pages/animalq.tsx";
import HolidayQ from "./pages/holidayq.tsx";
import AllXS from "./pages/allxs.tsx";
import LCM from "./pages/lcm.tsx";
import Wool from "./pages/wool.tsx";
import Fabricl from "./pages/fabricl.tsx"

export default function App() {
  return (
    <Router>
      <ChakraProvider>
        <div>
          <Routes>
            {[
              "/",
              "/index",
              "/home",
              "/allrestp",
              "/baskets",
              "/quilt",
              "/everydayq",
              "/fallc",
              "/folkart",
              "/four",
              "/freestuff",
              "/kindness",
              "/libertyhill",
              "/pdfs",
              "/tty",
              "/whatsnew",
              "/wholesale",
            ].map((path, index) => {
              return <Route key={index} path={path} element={<MainPage />} />;
            })}
            {["/animalq", "/season", "/winterq", "/springq", "/birdq"].map(
              (path, index) => {
                return <Route key={index} path={path} element={<AnimalQ />} />;
              }
            )}
            {["/holidayq", "/autumnq"].map((path, index) => {
              return <Route key={index} path={path} element={<HolidayQ />} />;
            })}
            {["/allxs", "/colorxs", "/holidayxs", "/springxs"].map(
              (path, index) => {
                return <Route key={index} path={path} element={<AllXS />} />;
              }
            )}
            <Route path="/lcm" element={<LCM />} />
            <Route path="/fabric" element={<Fabricl />} />
            {[
              "/wool",
              "/holidayp",
              "/springp",
              "/stitcheryc",
              "/pattern",
              "/springc",
            ].map((path, index) => {
              return <Route key={index} path={path} element={<Wool />} />;
            })}
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </ChakraProvider>
    </Router>
  );
}
