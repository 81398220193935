import {
  Box,
  Image,
  Center,
  Text,
  Divider,
  Button,
  ButtonGroup,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  CardFooter,
  Stack,
} from "@chakra-ui/react";
import Banner from "../assets/banner.jpg";
import Footer from "../components/footer.tsx";
import { isMobile } from "react-device-detect";
import { IconBrandEtsy, IconHeartHandshake } from "@tabler/icons-react";

import MainCards from "../data/maincards.js";

export default function MainPage() {
  return (
    <>
      <div
        style={{
          width: "100vw",
          paddingBottom: "20vw",
        }}
      >
        <Center>
          <a href="/">
            <Image
              src={Banner}
              alt="Logo Banner"
              height="13vh"
              p="10px"
              mb="20px"
            />
          </a>
        </Center>
        <Box pt="10">
          <Center style={{ textAlign: "center" }}>
            <Heading fontSize="3xl" pl="40px" pr="40px" style={{ textAlign: "center" }}>
              Welcome to the Prairie Grove Peddler website!
            </Heading>
          </Center>
          <Center style={{ textAlign: "center" }}>
            <Text fontSize="lg" px="120px">
              Click on each gallery to view Quilt, Cross Stitch, Craft, Wool & Punchneedle patterns. Visit our shops on Etsy and Payhip to purchase PDF download patterns and charts. For wholesale orders or other questions or concerns, please contact us at <a href="mailto:contact@prairiegrovepeddler.com" style={{ color: "black", textDecoration: "underline"}}>contact@prairiegrovepeddler.com</a>.
            </Text>
          </Center>
          <Center>
            <ButtonGroup isAttached variant="outline" pt="20px">
              <Button
                as="a"
                href="https://www.etsy.com/shop/prairiegrovepeddler"
                target="_blank"
                style={{ borderRadius: "5px 0px 0px 5px" }}
                variant="solid"
                colorScheme="green"
                leftIcon={<IconBrandEtsy />}
              >
                {" "}
                Go to Etsy
              </Button>
              <Button
                as="a"
                href="https://payhip.com/pgpeddler"
                target="_blank"
                style={{ borderRadius: "0px 5px 5px 0px" }}
                variant="solid"
                colorScheme="green"
                leftIcon={<IconHeartHandshake />}
              >
                {" "}
                Go to Payhip
              </Button>
            </ButtonGroup>
          </Center>
        </Box>
        <Box pt="40">
          <SimpleGrid columns={isMobile ? 1 : 3} spacing={5}>
            {MainCards.map((card) => (
              <Center key={card.id}>
                <Card
                  key={card.id}
                  maxW="sm"
                  ml="30px"
                  mr="30px"
                  variant="filled"
                  colorScheme="white"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                    "--card-bg": "white",
                  }}
                >
                  <CardBody>
                    <Center>
                      <Image
                        src={card.image}
                        alt={card.title + " Image"}
                        height="200px"
                        borderRadius="5px"
                      />
                    </Center>
                    <Stack mt="6" spacing="3">
                      <Heading fontSize="md">{card.title}</Heading>
                      <Text fontSize="sm">{card.description}</Text>
                    </Stack>
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <Center>
                      <Button
                        as="a"
                        href={card.link}
                        variant="solid"
                        colorScheme="green"
                      >
                        Take me there
                      </Button>
                    </Center>
                  </CardFooter>
                </Card>
              </Center>
            ))}
          </SimpleGrid>
        </Box>
      </div>
      <Divider colorScheme="green" />
      <Footer />
    </>
  );
}
