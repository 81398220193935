import React, {useCallback} from 'react';
import s3 from '../data/s3Data.js';
import { PhotoAlbum, RenderPhotoProps } from "react-photo-album";
import {
    Box,
    Center,
    Text,
    Divider,
    Button,
    ButtonGroup,
    Heading,
  } from "@chakra-ui/react";
import Footer from "../components/footer.tsx";
import Banner from "../assets/banner.jpg";
import ScrollToTop from "react-scroll-to-top";
import { IconArrowBigUpLinesFilled, IconBrandEtsy, IconHeartHandshake } from "@tabler/icons-react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

export default function Wool() {
    const titleRemove = "wool/"
    const [images, setImages] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
        s3.listObjects({ Bucket: 'prairiegrovepeddler-com' }, (err, data) => {
            data.Contents.map((item) => {
                if (item.Key.startsWith(titleRemove)) {
                    let img = new Image();
                    img.src = "https://images.prairiegrovepeddler.com/" + encodeURIComponent(item.Key)
                    img.onload = () => {
                        setImages(images => [...images, { src: "https://images.prairiegrovepeddler.com/" + encodeURIComponent(item.Key), width: img.width, height: img.height, title: item.Key }])
                    } 
                }
                return null;
            }) 
        })
        setLoaded(true)
    }, [])

    const renderPhoto = useCallback(
        ({ imageProps: { alt, style, ...rest }, photo: {src, title} }: RenderPhotoProps) => (
            <div className="featuredImage">
                <Box style={{ boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px", borderRadius: "5px", marginBottom: "10px", key: {title} }}>
                    <BrowserView>
                        <img 
                            alt={title} 
                            style={{ ...style, objectFit: "contain", borderRadius: "5px" }} 
                            {...rest} 
                        />
                    </BrowserView>
                    <MobileView>
                        <LazyLoadImage 
                            alt={title} 
                            style={{ ...style, objectFit: "contain", borderRadius: "5px" }} 
                            {...rest} 
                        /> 
                    </MobileView>
                    <Center>
                        <Text p="5px">#{title.split(titleRemove)[1].split("-")[0]} {title.split(titleRemove)[1].split("-")[1].split(".")[0]}</Text>
                    </Center>
                </Box>
            </div>
        ),[]
    );
    if (loaded) {
        return (
            <>
                <ScrollToTop smooth component={
                    <Center>
                        <IconArrowBigUpLinesFilled />
                    </Center>
                }/>
                <Box
                    p="10px"
                >
                    <Center>
                        <a href="/">
                            <img src={Banner} alt="Logo Banner" style={{height: "13vh", padding: "10px", marginBottom: "20px" }} />
                        </a>
                    </Center>
                    <Box p="10">
                        <Center style={{ textAlign: "center" }}>
                        <Heading fontSize="3xl" pl="40px" pr="40px">
                            Wool, Craft & Punchneedle Patterns
                        </Heading>
                        </Center>
                        <Center>
                        <Text fontSize="lg" pl="40px" pr="40px" style={{ textAlign: "center" }}>
                        The Prairie Grove Peddler offers wool, craft & punch needle patterns for everyday use and every season. We also offer some as Instant Download PDF.{" "}
                        </Text>
                        </Center>
                        <Center>
                        <ButtonGroup isAttached variant="outline" pt="20px">
                            <Button
                            as="a"
                            href="https://www.etsy.com/shop/prairiegrovepeddler"
                            target="_blank"
                            style={{ borderRadius: "5px 0px 0px 5px" }}
                            variant="solid"
                            colorScheme="green"
                            leftIcon={<IconBrandEtsy />}
                            >
                            {" "}
                            Go to Etsy
                            </Button>
                            <Button
                            as="a"
                            href="https://payhip.com/pgpeddler"
                            target="_blank"
                            style={{ borderRadius: "0px 5px 5px 0px" }}
                            variant="solid"
                            colorScheme="green"
                            leftIcon={<IconHeartHandshake />}
                            >
                            {" "}
                            Go to Payhip
                            </Button>
                        </ButtonGroup>
                        </Center>
                    </Box>
                    <PhotoAlbum
                        photos={images.reverse()}
                        layout="columns"
                        spacing={10}
                        columns={isMobile ? 1 : 5}
                        renderPhoto={renderPhoto}
                    />
                </Box>
                <Divider colorScheme="green"/>
                <Footer />
            </>
        )
    } else {
        return (
            null
        )
    }
}