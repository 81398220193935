//to main page, (/allrestp, /basksets, /quilt, /everydayq, /fabric, /fallc, /folkart, /four, /freestuff, /index, /kindness, /libertyhill, /pdfs, /tty, /whatsnew, /wholesale, /home)

const MainCards = [
  {
    id: 1,
    title: "Animal & Seasonal Quilts",
    itemsCount: 69,
    description:
      "Quilts for all seasons and all animals. Contains 69 unique quilt patterns.",
    image: "/images/mainpage/animalq main page.webp",
    link: "/animalq", // also (/season, /winterq, /springq, /birdq)
  },
  {
    id: 2,
    title: "Fall & Holiday Quilts",
    itemsCount: 62,
    description:
      "Quilts for Fall and the holidays. Contains 62 unique quilt patterns.",
    image: "/images/mainpage/holidayq main page.webp",
    link: "/holidayq", //also (/autumnq)
  },
  {
    id: 3,
    title: "Cross Stitch Charts",
    itemsCount: 57,
    description:
      "Cross stitch charts for all general themes. Contains 57 unique cross stitch charts.",
    image: "/images/mainpage/allxs main page.webp",
    link: "/allxs", //also (/colorxs, /holidayxs, /springxs)
  },
  {
    id: 4,
    title: "Little Candle Mats Cross Stitch Charts",
    itemsCount: 20,
    description:
      "Cross stitch charts for little candle mats. Contains 20 unique cross stitch charts.",
    image: "/images/mainpage/lcm main page.webp",
    link: "/lcm",
  },
  {
    id: 5,
    title: "Wool, Craft & Punchneedle Patterns",
    itemsCount: 57,
    description:
      "Wool, craft and punchneele patterns for all general themes. Contains 57 unique patterns.",
    image: "/images/mainpage/wool main page.webp",
    link: "/wool", //also (/holidayp, /springp, /stitcheryc, /pattern, /springc )
  },
  {
    id: 6,
    title: "Fabric Lines",
    itemsCount: 33,
    description:
      "Fabric Lines in many general themes. Contains 33 unique items.",
    image: "/images/mainpage/fabricl main page.webp",
    link: "/fabric"
  }
];
export default MainCards;
