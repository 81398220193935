import React from "react";
import {
  Text,
  Center,
  IconButton,
  ButtonGroup,
  Tooltip,
} from "@chakra-ui/react";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandBlogger,
  IconBrandPinterest,
} from "@tabler/icons-react";

export default function Footer() {
  let year = new Date().getFullYear();
  return (
    <>
      <div
        className="footerText"
        style={{
          zIndex: "1000",
          borderRadius: "5px",
          marginBottom: "20px",
          backgroundColor: "white",
          paddingTop: "20px",
        }}
      >
        <Center>
          <Text fontSize="sm" ml="40px" mr="40px" p="5px" as="b">
            Visit our Etsy Store for specials on patterns, kits and limited
            fabric bundles and follow us on Social Media
          </Text>
        </Center>
        <Center>
          <ButtonGroup isAttached variant="solid" pt="20px">
            <Tooltip label="Facebook" aria-label="Facebook">
              <IconButton
                icon={<IconBrandFacebook />}
                as="a"
                target="_blank"
                colorScheme="green"
                rel="noopener noreferrer"
                href="https://www.facebook.com/prairiegrove.crossstitch"
                style={{ borderRadius: "5px 0px 0px 5px" }}
              />
            </Tooltip>
            <Tooltip label="Instagram" aria-label="Instagram">
              <IconButton
                icon={<IconBrandInstagram />}
                as="a"
                target="_blank"
                colorScheme="green"
                rel="noopener noreferrer"
                href="https://www.instagram.com/prairiegrovepeddler/"
                style={{ borderRadius: "0px" }}
              />
            </Tooltip>
            <Tooltip label="Blogger" aria-label="Blogger">
              <IconButton
                icon={<IconBrandBlogger />}
                as="a"
                target="_blank"
                colorScheme="green"
                rel="noopener noreferrer"
                href="https://prairiegrovepeddler.blogspot.com/"
                style={{ borderRadius: "0px" }}
              />
            </Tooltip>
            <Tooltip label="Pinterest" aria-label="Pinterest">
              <IconButton
                icon={<IconBrandPinterest />}
                as="a"
                target="_blank"
                colorScheme="green"
                rel="noopener noreferrer"
                href="https://www.pinterest.com/prairiegrovegrl/"
                style={{ borderRadius: "0px 5px 5px 0px" }}
              />
            </Tooltip>
          </ButtonGroup>
        </Center>
        <Center style={{ textAlign: "center" }}>
          <Text fontSize="sm" mt="30px" p="5px" as="b">
            © 2004-{year} Prairie Grove Peddler All Rights Reserved
          </Text>
        </Center>
        <Center style={{ textAlign: "center" }}>
          <Text fontSize="sm" m="10px" px="120px" as="b">
            Intended to use for fun. Mass producing, catalog sales, photocopying
            and all other forms of reproduction are strictly prohibited.
            Reselling on eBay or any other online shop is prohibited.
          </Text>
        </Center>
      </div>
    </>
  );
}
