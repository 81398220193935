import { Box, Image, Center, Text, Button } from "@chakra-ui/react";
import Banner from "../assets/banner.jpg";
export default function ErrorPage() {
  window.document.title = "404 - Page Not Found";
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box pt="20">
        <Center>
          <Image src={Banner} alt="Logo Banner" height="13vh" p="10px" />
        </Center>
        <Center>
          <Text fontSize="5xl">404</Text>
        </Center>
        <Center>
          <Text fontSize="3xl">Page Not Found</Text>
        </Center>
        <Center pt="20">
          <Button
            variant="solid"
            colorScheme="green"
            onClick={() => {
              window.history.back();
            }}
          >
            Go back to previous page
          </Button>
        </Center>
      </Box>
    </div>
  );
}
